import styles from "./mobile-blocker.module.scss";

const MobileBlocker = () => {
  return (
    <div className={styles.mobileBlocker}>
      <div>Device resolution not supported!</div>
      <div>
        We are sorry. This is an early build of our application and it doesn't
        support this screen resolution currently!
      </div>
      <div>
        Please try using a dsevice with a bigger resolution or try resizing this
        window.
      </div>
    </div>
  );
};

export default MobileBlocker;
