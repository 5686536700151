// @ts-nocheck
import ReportContentProps from "./props";
import Tabs from "../Tabs";
import OverviewAnalysis from "./OverviewAnalysis";
import HeuristicEvaluation from "./HeuristicEvaluation";
import TechnicalPerformance from "./TechnicalPerformance";
import AnalyticsIntegration from "./AnalyticsIntegration";
import React from "react";

const ReportContent = ({ data }: ReportContentProps) => {
  const tabsData = [
    {
      name: "Overview Analysis",
      key: "tab1",
      content: <OverviewAnalysis data={data} />,
    },
    {
      name: "Heuristic Evaluation",
      key: "tab2",
      content: <HeuristicEvaluation data={data} />,
    },
    {
      name: "Technical Performance",
      key: "tab3",
      content: <TechnicalPerformance data={data} />,
    },
    {
      name: "Analytics Integration",
      key: "tab4",
      content: <AnalyticsIntegration data={data} />,
    },
  ];

  return (
    <div id="reportContent">
      <Tabs tabs={tabsData} />
    </div>
  );
};

export default ReportContent;
