import { useCallback, useState } from "react";
import CollapsableProps from "./props";
import styles from "./collapsable.module.scss";
import Button from "../Button";
import { RiAddLine, RiSubtractLine } from "@remixicon/react";
import Divider from "../Divider";

const Collapsable = ({ title, children, collapsed }: CollapsableProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed ?? false);

  const toggleCollapseState = useCallback(() => {
    setIsCollapsed((curr) => !curr);
  }, []);

  return (
    <>
      <div className={styles.collapsable}>
        <div className={styles.titleSection}>
          <div>{title}</div>
          <Button onClick={toggleCollapseState} type="ghost">
            {isCollapsed ? <RiAddLine /> : <RiSubtractLine />}
          </Button>
        </div>
        {isCollapsed ? null : <>{children}</>}
      </div>
      <Divider />
    </>
  );
};

export default Collapsable;
