import ButtonProps from "./props";
import styles from "./button.module.scss";

const defaultfunction = () => {};

const Button = ({
  children,
  className,
  block,
  type: buttonType = "secondary",
  submit,
  style,
  disabled,
  onClick = defaultfunction,
  textAlign = "center",
}: ButtonProps) => {
  const buttonClassNames = [
    styles.button,
    buttonType === "primary" && styles.primary,
    buttonType === "danger" && styles.danger,
    buttonType === "google" && styles.google,
    buttonType === "stop" && styles.stop,
    buttonType === "link" && styles.link,
    buttonType === "ghost" && styles.ghost,
    block && styles.block,
    className,
    buttonType === "link" ? styles.left : styles[textAlign],
  ]
    .filter(Boolean)
    .join(" ");
  return (
    <button
      disabled={disabled}
      style={style}
      type={submit ? "submit" : "button"}
      className={buttonClassNames}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
