import { useCookies } from "react-cookie";
import styles from "./reportContent.module.scss";
import Button from "../Button";
import { RiRestartLine } from "@remixicon/react";
import DownloadPDf from "./DownloadPDF";
import Divider from "../Divider";
import ReportContentProps from "./props";

const ReportHeader = ({
  restartAudit,
  currentDate,
  data,
}: ReportContentProps) => {
  const [cookies] = useCookies(["tokens"]); // Access cookies
  const hasToken = Boolean(cookies.tokens?.access); // Check if the access token exists
  return (
    <>
      <div style={{ width: "100%" }}>
        <div className={`${styles.buttonContainer}`}>
          <div className={`${styles.reportCreated}`}>{currentDate}</div>
          <div className={`${styles.buttonGroup}`}>
            <Button onClick={restartAudit} type="primary">
              <RiRestartLine size={18} /> RESTART AUDIT
            </Button>
            <div className={styles.buttonSpacer}></div>
            <DownloadPDf
              data={data}
              currentDate={currentDate}
              disabled={!hasToken}
            />
          </div>
        </div>
      </div>
      <Divider />
    </>
  );
};

export default ReportHeader;
